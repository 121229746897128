// Copyright 1999-2021. Plesk International GmbH. All rights reserved.

import { ModalDialogBox } from './modal-dialog-box';
import addStatusMessage from './addStatusMessage';
import clearStatusMessages from './clearStatusMessages';
import render from './render';
import ce from './createElement';

export class PopupForm extends ModalDialogBox {
    _initConfiguration(config) {
        super._initConfiguration({
            cls: 'popup-panel',
            ...config,
        });

        this._popupContentAreaId = `${this._id}-popup-content-area`;
        this._titleAreaId = `${this._id}-title-area`;
        this._hintAreaId = `${this._id}-hint-area`;
        this._hint1AreaId = `${this._id}-hint1-area`;
        this._boxAreaId = `${this._id}-box-area`;
        this._contentAreaId = `${this._id}-content-area`;

        this._actionButtonsId = `${this._id}-action-buttons`;
        this._leftActionButtonsAreaId = `${this._id}-left-action-buttons-area`;
        this._rightActionButtonsAreaId = `${this._id}-right-action-buttons-area`;

        this._titleCls = this._getConfigParam('titleCls', '');
        this._scrollable = this._getConfigParam('scrollable');
        this._fullHeight = this._getConfigParam('fullHeight');
        this._isRemoved = false;

        if (this._scrollable) {
            this._cls += ' popup-panel-scrollable';
        }

        if (this._fullHeight) {
            this._cls += ' popup-panel-full-height';
        }
    }

    _initComponentElement() {
        super._initComponentElement();
        render(this._componentElement, this.view());
        this.show();
    }

    view() {
        return ce('.popup-wrapper', ce('.popup-container', [
            ce('.popup-heading', ce('.popup-heading-area', [
                ce('span.popup-heading-actions', this._getHeadingActions()),
                ce(`h2.${this._titleCls}`,
                    ce(`span#${this._titleAreaId}`, {
                        title: this._getConfigParam('title'),
                    }, this._getConfigParam('title'))
                ),
            ])),
            ce('.popup-content', ce(`#${this._popupContentAreaId}.popup-content-area`,
                this._getContentArea()
            )),
        ]));
    }

    _getHeadingActions() {
        return '';
    }

    _getContentArea() {
        return [
            ce(`p#${this._hint1AreaId}`),
            ce(`span#${this._hintAreaId}`),
            this._getBoxArea(),
            this._getButtonsArea(),
        ];
    }

    _getBoxArea() {
        return ce(`#${this._boxAreaId}.form-box`, ce('.box-area', ce('.content',
            ce(`#${this._contentAreaId}.content-area`)
        )));
    }

    _getButtonsArea() {
        let buttonsRow = ce(`#${this._rightActionButtonsAreaId}.field-value`, ' ');
        if (this._getConfigParam('singleRowButtons')) {
            buttonsRow = ce('.single-row', buttonsRow);
        } else {
            buttonsRow = [
                ce(`#${this._leftActionButtonsAreaId}.field-name`, ' '),
                buttonsRow,
            ];
        }
        return ce(`#${this._actionButtonsId}.btns-box`, ce('.box-area', ce('.form-row', buttonsRow)));
    }

    toggleButtonsArea(bool) {
        if (this._scrollable) {
            this._componentElement.classList[bool ? 'add' : 'remove']('popup-panel-scrollable');
        }
        document.getElementById(this._actionButtonsId).style.display = bool ? '' : 'none';
    }

    setBoxType(type) {
        document.getElementById(this._boxAreaId).className = type;
        if ('list-box' === type) {
            document.getElementById(this._actionButtonsId).classList.add('no-separator');
            document.getElementById(this._actionButtonsId).classList.add('simple-box');
        }
        if ('form-box' === type) {
            document.getElementById(this._actionButtonsId).classList.remove('no-separator');
            document.getElementById(this._actionButtonsId).classList.remove('simple-box');
        }
        if ('fm-box' === type) {
            document.getElementById(this._boxAreaId).className = 'list-box';
            document.getElementById(this._actionButtonsId).classList.add('no-separator');
        }
    }

    /**
     * @param {String} cls
     */
    setTitleType(cls) {
        document.getElementById(this._titleAreaId).parentNode.className = cls;
    }

    /**
     * @param {String} text
     */
    setTitle(text) {
        document.getElementById(this._titleAreaId).innerHTML = text;
        document.getElementById(this._titleAreaId).setAttribute('title', text);
    }

    /**
     * @param {String} text
     */
    setHint(text) {
        if (text) {
            document.getElementById(this._hintAreaId).innerHTML = text;
            document.getElementById(this._hintAreaId).style.display = '';
        } else {
            document.getElementById(this._hintAreaId).style.display = 'none';
        }
    }

    /**
     * @param {String} text
     */
    setHint1(text) {
        if (text) {
            document.getElementById(this._hint1AreaId).innerHTML = text;
            document.getElementById(this._hint1AreaId).style.display = '';
        } else {
            document.getElementById(this._hint1AreaId).style.display = 'none';
        }
    }

    /**
     * Remove this component.
     */
    remove() {
        this._isRemoved = true;
        this._renderTarget.parentNode.removeChild(this._renderTarget);
    }

    /**
     * @param {String} title
     * @param {Function} handler
     * @param {Boolean} [isDefault]
     * @param {Boolean} [isAction]
     * @param {Object} [params]
     * @returns {Element}
     */
    addRightButton(title, handler, isDefault, isAction, params) {
        const button = this._createButton(title, handler, isDefault, isAction, params);
        render(document.getElementById(this._rightActionButtonsAreaId), button);
        return button;
    }

    /**
     * @param {String} title
     * @param {Function} handler
     * @param {Boolean} [isDefault]
     * @param {Boolean} [isAction]
     * @param {Object} [params]
     * @returns {Element}
     */
    addLeftButton(title, handler, isDefault, isAction, params) {
        const button = this._createButton(title, handler, isDefault, isAction, params);
        render(document.getElementById(this._leftActionButtonsAreaId), button);
        return button;
    }

    /**
     * Clear right buttons area.
     */
    removeRightButtons() {
        document.getElementById(this._rightActionButtonsAreaId).innerHTML = ' ';
    }

    /**
     * Clear left buttons area.
     */
    removeLeftButtons() {
        document.getElementById(this._leftActionButtonsAreaId).innerHTML = ' ';
    }

    /**
     * Update list size.
     */
    resizeList() {
        if (this._isRemoved || !document.getElementById(this._contentAreaId)) {
            return;
        }

        const minHeigh = 150;
        const list = document.getElementById(this._contentAreaId).querySelector('table');
        const listContainer = document.getElementById(this._contentAreaId).querySelector('.list');

        const buttonsHeight = Element.getHeight(document.getElementById(this._actionButtonsId));
        const top = Element.cumulativeOffset(list)[1];
        const height = Element.getHeight(list);
        // 100px below dialog
        let newHeight = document.body.clientHeight - top - buttonsHeight - 100;

        if (newHeight > height) {
            listContainer.style.height = '';
            listContainer.classList.remove('with-scroll');
            return;
        }

        if (newHeight < minHeigh) {
            newHeight = minHeigh;
        }

        listContainer.style.height = `${newHeight}px`;
        listContainer.style.overflowY = 'auto';
        listContainer.style.overflowX = 'hidden';
        listContainer.classList.add('with-scroll');
    }

    /**
     * @param {String} title
     * @param {Function} handler
     * @param {Boolean} [isDefault]
     * @param {Boolean} [isAction]
     * @param {Object} [params]
     * @returns {Element}
     * @private
     */
    _createButton(title, handler, isDefault, isAction, params = {}) {
        // eslint-disable-next-line react/button-has-type
        const button = document.createElement('button');
        Object.keys(params).forEach(name => {
            if (params[name] === true) {
                button.setAttribute(name, name);
            } else if (params[name] !== null && params[name] !== false) {
                button.setAttribute(name, params[name]);
            }
        });
        button.classList.add('btn');
        if (isAction) {
            button.classList.add('action');
        }

        button.type = isDefault ? 'submit' : 'button';
        button.value = '';
        button.innerHTML = title;

        button.addEventListener(
            'click',
            event => {
                this._onClick(event, handler.bind(this));
            }
        );

        return button;
    }

    _updateButton(button, config) {
        $H(config).each(({ key, value }) => {
            switch (key) {
                case 'title':
                    button.innerHTML = value;
                    break;
                case 'disabled':
                    button.disabled = !!value;
                    if (value) {
                        button.classList.add('disabled');
                    } else {
                        button.classList.remove('disabled');
                    }
                    break;
            }
        });
    }

    _onClick(event, handler) {
        event.preventDefault();
        event.stopPropagation();
        handler(event);
    }

    _addEvents() {
        super._addEvents();

        if (document.getElementById(this._hintAreaId)) {
            document.getElementById(this._hintAreaId).style.display = 'none';
        }
        if (document.getElementById(this._hint1AreaId)) {
            document.getElementById(this._hint1AreaId).style.display = 'none';
        }
    }

    _clearMessages() {
        const contentElement = document.getElementById(this._popupContentAreaId);

        ['.msg-error', '.msg-info', '.msg-warning'].forEach(msgClass => {
            contentElement.querySelectorAll(msgClass).forEach(messageElement => {
                messageElement.parentNode.removeChild(messageElement);
            });
        });
    }

    _addErrorMessage(message) {
        this._addStatusMessage('error', message);
    }

    _addStatusMessage(status, message) {
        render(document.getElementById(this._popupContentAreaId), (
            `<div class="msg-box msg-${status}">` +
                '<div>' +
                    '<div>' +
                        '<div>' +
                            '<div>' +
                                '<div>' +
                                    `<div class="msg-content">${message}</div>` +
                                '</div>' +
                            '</div>' +
                        '</div>' +
                    '</div>' +
                '</div>' +
            '</div>'
        ), 'top');
    }

    _userError(error) {
        clearStatusMessages();
        addStatusMessage('error', error);
        this._close();
    }

    _internalError(error) {
        clearStatusMessages();
        addStatusMessage('error', `Internal error: ${error}`);
        this._close();
    }
}

// TODO EXTDOCKER-50: Cannot open docker extension: Cannot read property 'push' of undefined
PopupForm.subclasses = [];
