// Copyright 1999-2021. Plesk International GmbH. All rights reserved.

import { Component } from '../component';
import addStatusMessage from '../addStatusMessage';
import clearStatusMessages from '../clearStatusMessages';
import showInternalError from '../showInternalError';
import prepareUrl from '../prepareUrl';
import render from '../render';
import ce from '../createElement';
import escapeHtml from '../escapeHtml';
import emptyFn from '../emptyFn';
import { ESC, ENTER } from '../keyCode';
import api from '../api';
import { getTypeIcon } from '../../helpers/fileManager';

import './tree.less';

/**
 * The widget displays directories and files in the webspace, allows selection
 *
 * Example usage:
 *
 *     @example
 *     new FileManager.Tree({
 *         renderTo: document.body,
 *         rootNodeTitle: 'Root node',
 *         data: [{
 *             name: 'folder',
 *             isDirectory: true,
 *             icon: '/icons/16/plesk/file-folder.png'
 *         }, {
 *             name: 'file.html',
 *             isDirectory: false,
 *             icon: '/icons/16/plesk/file-html.png'
 *         }]
 *     });
 */
export class Tree extends Component {
    /**
     * @cfg {Object[]} data=[]
     */
    /**
     * @cfg {String} dataUrl=""
     */
    /**
     * @cfg {Boolean} showFiles=false
     */

    _initConfiguration(config) {
        super._initConfiguration(config);

        this._data = this._getConfigParam('data', null);
        this._dataUrl = this._getConfigParam('dataUrl', '');
        this._createFolderUrl = this._getConfigParam('createFolderUrl', '');
        this._onNodeClick = this._getConfigParam('onNodeClick', emptyFn);
        this._rootNodeTitle = this._getConfigParam('rootNodeTitle', '');
        this._onReload = this._getConfigParam('onReload', emptyFn);
        this._showFiles = this._getConfigParam('showFiles', false);
        this._filterNodes = this._getConfigParam('filterNodes', () => true);
        if (this._getConfigParam('showMessage')) {
            this._showMessage = this._getConfigParam('showMessage');
        }
    }

    _initComponentElement() {
        super._initComponentElement();
        if (null === this._data) {
            this.reload();
        } else {
            this._initTreeView();
        }
    }

    _initTreeView() {
        this._componentElement.innerHTML = '<div class="tree-wrap"></div>';
        const container = this._componentElement.firstChild;

        this._insertNode(container, {
            name: this._rootNodeTitle,
            path: '/',
            htmlElement: 'div',
            cssClass: 'tree-item-root',
            isRootDirectory: true,
            isDirectory: true,
        });
        render(container, this._getNodes(this._data));
    }

    _getNodes(items) {
        const ulElement = new Element('ul', { class: 'tree-container' });

        items.filter(this._filterNodes).forEach(this._insertNode.bind(this, ulElement));

        return ulElement;
    }

    _insertNode(container, item, position) {
        const element = document.createElement(item.htmlElement || 'li');
        element.className = `tree-item ${item.cssClass ? item.cssClass : ''}`;
        element.innerHTML = '<div class="tree-item-wrap"></div>';
        const wrapper = element.firstChild;
        element.data = item;

        const itemSelect = document.createElement('div');
        itemSelect.className = 'tree-item-select';
        render(wrapper, itemSelect);
        this._addCommonEvents(itemSelect, item, element);
        if (item.isDirectory && !item.isRootDirectory) {
            render(wrapper, `<span class="tree-item-state"><img src="${require('images/tree-open.gif')}" height="16" width="16"></span>`);
            wrapper.querySelector('.tree-item-state').addEventListener('click', this._onNodeToggle.bind(this, item.path, element));
            wrapper.addEventListener('dblclick', this._onNodeToggle.bind(this, item.path, element));
            wrapper.querySelector('.tree-item-state').addEventListener('mouseover', this._onNodeMouseover.bind(this, element));
            wrapper.querySelector('.tree-item-state').addEventListener('mouseleave', this._onNodeMouseleave.bind(this, element));
        } else if (!item.isRootDirectory) {
            render(wrapper, `<span class="tree-item-state"><img src="${require('images/blank.gif')}" height="16" width="16"></span>`);
        }
        const linkElement = document.createElement('a');
        linkElement.className = 'tree-item-content';
        linkElement.innerHTML = `<span><img src="${getTypeIcon(item)}" alt=""><b>${escapeHtml(item.name)}</b></span>`;
        this._addCommonEvents(linkElement, item, element);
        render(wrapper, linkElement);

        render(container, element, position === 'top' ? 'top' : 'bottom');
    }

    _addCommonEvents(target, item, element) {
        target.addEventListener('mouseover', this._onNodeMouseover.bind(this, element));
        target.addEventListener('mouseleave', this._onNodeMouseleave.bind(this, element));
        target.addEventListener('click', event => {
            this._onNodeSelect(event, element);
        });
        target.addEventListener('click', this._onNodeClick.bind(this, item.path, element));
    }

    _onNodeToggle(directoryPath, containerElement) {
        const subTreeElement = containerElement.querySelector('ul');
        const expandElement = containerElement.querySelector('.tree-item-state');

        if (subTreeElement) {
            expandElement.innerHTML = `<img src="${require('images/tree-open.gif')}" height="16" width="16">`;
            subTreeElement.parentNode.removeChild(subTreeElement);
            containerElement.data.showNewNode = false;
            return;
        }

        this._loadNode(directoryPath, containerElement);
    }

    _loadNode(directoryPath, containerElement) {
        if (containerElement.querySelector('.tree-item-state').querySelector('.js-loader')) {
            return;
        }
        this._onNodeLoadStart(containerElement);
        api.get(prepareUrl(this._dataUrl), { rootDir: directoryPath, showFiles: this._showFiles })
            .then(this._onNodeLoadSuccess.bind(this, containerElement))
            .catch(this._onNodeLoadFailure.bind(this));
    }

    _isNodeExpanded(nodeElement) {
        return !!nodeElement.querySelector('ul') || nodeElement.classList.contains('tree-item-root');
    }

    _onNodeLoadStart(containerElement) {
        const expandElement = containerElement.querySelector('.tree-item-state');
        expandElement.innerHTML = `<img src="${require('images/indicator.gif')}" height="16" width="16" class="js-loader">`;
    }

    _onNodeLoadSuccess(containerElement, items) {
        const expandElement = containerElement.querySelector('.tree-item-state');
        if (items.status) {
            expandElement.innerHTML = `<img src="${require('images/tree-open.gif')}" height="16" width="16">`;
            this._showMessage(items.status, items.message);
            return;
        }

        clearStatusMessages();
        expandElement.innerHTML = `<img src="${require('images/tree-close.gif')}" height="16" width="16">`;
        render(containerElement, this._getNodes(items));
        if (containerElement.data.showNewNode) {
            this.showNewNode(containerElement);
        }
    }

    _showMessage(status, message) {
        clearStatusMessages();
        addStatusMessage(status, message);
    }

    _onNodeSelect(event, containerElement) {
        if (event) {
            event.preventDefault();
        }
        this.resetSelection();
        containerElement.querySelector('.tree-item-wrap').classList.add('tree-item-row-active');
        this.hideNewNode();
    }

    resetSelection() {
        this._componentElement.querySelectorAll('.tree-item-wrap').forEach(element => {
            element.classList.remove('tree-item-row-active');
        });
    }

    _onNodeMouseover(containerElement) {
        this.resetHover();
        containerElement.querySelector('.tree-item-select').classList.add('tree-item-hover');
    }

    _onNodeMouseleave(containerElement) {
        this.resetHover();
        containerElement.querySelector('.tree-item-select').classList.remove('tree-item-hover');
    }

    resetHover() {
        this._componentElement.querySelectorAll('.tree-item-select').forEach(element => {
            element.classList.remove('tree-item-hover');
        });
    }

    /**
     * @param {String} directory
     */
    setDirectory(directory) {
        const node = this._getNodeElement(directory);
        if (node) {
            this._onNodeSelect(null, node);
        }
    }

    reload() {
        this._componentElement.innerHTML = '<div class="ajax-loading">Please wait...</div>';

        api.get(prepareUrl(this._dataUrl), { rootDir: '/', showFiles: this._showFiles })
            .then(this._onFullReloadSuccess.bind(this))
            .catch(this._onNodeLoadFailure.bind(this));
    }

    _onFullReloadSuccess(data) {
        this._data = data;
        this._initTreeView();
        this._onReload();
    }

    _onNodeLoadFailure() {
        showInternalError('Failed to load tree data.');
    }

    reloadPath(path) {
        if (path === '/') {
            this.reload();
            return;
        }

        const node = this._getNodeElement(path);
        if (!node) {
            return;
        }

        if (this._isNodeExpanded(node)) {
            const el = node.querySelector('ul');
            el.parentNode.removeChild(el);
            this._loadNode(path, node);
        }
    }

    _getNodeElement(path) {
        if (typeof path !== 'string') {
            return null;
        }
        const nodes = this._componentElement.querySelectorAll('.tree-item');
        for (let i = 0; i < nodes.length; i++) {
            if (nodes[i].data.path === path) {
                return nodes[i];
            }
        }
        const lastSlashIndex = path.lastIndexOf('/');
        if (lastSlashIndex > 0) {
            return this._getNodeElement(path.slice(0, lastSlashIndex));
        }
        return null;
    }

    getSelectedNode() {
        const selectedElement = this._componentElement.querySelector('.tree-item-row-active');
        if (!selectedElement) {
            return null;
        }

        return selectedElement.closest('.tree-item');
    }

    getSelectedItemData() {
        const selectedElement = this.getSelectedNode();
        if (!selectedElement) {
            return null;
        }

        return selectedElement.data;
    }

    expandNode(node) {
        if (!node) {
            node = this.getSelectedNode();
        }
        if (this._isNodeExpanded(node)) {
            return;
        }

        this._loadNode(node.data.path, node);
    }

    showNewNode(parentNode) {
        if (!parentNode) {
            parentNode = this.getSelectedNode();
        }
        if (!parentNode) {
            // Process root node
            parentNode = this._componentElement.querySelector('.tree-item-root');
        }
        parentNode.data.showNewNode = true;
        if (!this._isNodeExpanded(parentNode)) {
            this.expandNode(parentNode);
            return;
        }
        let container = parentNode.querySelector('ul.tree-container');
        if (!container) {
            // Process root node
            container = parentNode.nextElementSibling;
        }
        this._insertNewNode(container, {
            name,
            path: parentNode.data.path,
            icon: '/icons/16/plesk/file-folder.png',
            isDirectory: true,
            type: 'dir',
        }, 'top');
    }

    hideNewNode() {
        const newNode = this._componentElement.querySelector('.js-tree-item-new');
        if (!newNode) {
            return;
        }
        let parentNode = newNode.closest('.tree-item');
        if (!parentNode) {
            // Process root node
            parentNode = this._componentElement.querySelector('.tree-item-root');
        }
        parentNode.data.showNewNode = false;
        newNode.parentNode.removeChild(newNode);
    }

    _insertNewNode(container, item, position) {
        if (container.querySelector('.js-tree-item-new')) {
            return;
        }
        const element = ce('li.tree-item js-tree-item-new',
            {
                onrender(el) {
                    el.data = item;
                },
            },
            ce('div.tree-item-wrap',
                ce('span.tree-item-state', ce('img', { src: require('images/blank.gif') })),
                ce('div.tree-item-content',
                    ce('div.input-btn-group',
                        ce('i.icon-folder'),
                        ce('input.form-control', { type: 'text', onkeydown: this._onNewNodeKeyDown.bind(this) }),
                        ce('button.btn btn-icon-only input-btn',
                            { type: 'button', onclick: this._onCreateFolder.bind(this) }, ce('i.icon-save')),
                        ce('button.btn btn-icon-only input-btn',
                            { type: 'button', onclick: this._onCancelCreateFolder.bind(this) }, ce('i.icon-cancel'))
                    )
                )
            )
        );

        render(container, element, position);
        container.querySelector('.js-tree-item-new input').focus();
    }

    _onCreateFolder(e) {
        const name = e.target.closest('.input-btn-group').querySelector('.form-control').value;
        const newNode = e.target.closest('.js-tree-item-new');
        let parentNode = newNode.parentNode.closest('.tree-item');
        if (!parentNode) {
            // Process root node
            parentNode = newNode.closest('ul.tree-container').previousElementSibling;
        }
        api.post(prepareUrl(this._createFolderUrl), { currentDir: newNode.data.path, newDirectoryName: name })
            .then(response => this._onFolderCreated(name, parentNode, response));
    }

    _onFolderCreated(name, parentNode, { status, message }) {
        const row = parentNode.closest('.form-row');
        let errorMessage;
        if (status === 'success') {
            const path = `${parentNode.data.path}/${name}`;
            let container = parentNode.querySelector('ul.tree-container');
            if (!container) {
                // Process root node
                container = parentNode.nextElementSibling;
            }
            this.hideNewNode();
            this._insertNode(container, {
                name,
                path,
                isDirectory: true,
            }, 'top');
            this._onNodeSelect(null, container);
            row.classList.remove('error');
            errorMessage = row.querySelector('.field-value .field-errors');
            if (errorMessage) {
                errorMessage.parentNode.removeChild(errorMessage);
            }
        } else {
            row.classList.add('error');
            errorMessage = ce('span.field-errors', ce('span.error-hint', message));
            render(row.querySelector('.field-value'), errorMessage);
        }
    }

    _onCancelCreateFolder() {
        this.hideNewNode();
    }

    _onNewNodeKeyDown(e) {
        switch (e.keyCode) {
            case ENTER:
                e.preventDefault();
                this._onCreateFolder(e);
                break;
            case ESC:
                e.preventDefault();
                this._onCancelCreateFolder(e);
                break;
        }
    }
}
