// Copyright 1999-2021. Plesk International GmbH. All rights reserved.

import { createElement } from 'react';

import PropTypes from 'prop-types';
import { Link as RouteLink, matchPath } from 'react-router-dom';
import { createLocation } from 'history';
import routes from '../routes';

const urlMapping = {
    '/plesk/admin-panel=server.tools/': '/admin/server/tools',
};

const Link = ({ to, ...props }) => {
    if (urlMapping[to]) {
        to = urlMapping[to];
    }

    const { pathname } = createLocation(to);
    const isMatch = routes.some(route => matchPath(pathname, route));

    if (isMatch) {
        return <RouteLink to={to} {...props} />;
    }

    return <a href={to} {...props} />;
};

Link.propTypes = {
    to: PropTypes.string,
};

Link.defaultProps = {
    to: null,
};

Link.contextTypes = {
    router: PropTypes.object,
};

export default Link;
