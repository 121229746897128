// Copyright 1999-2021. Plesk International GmbH. All rights reserved.

import { createContext, useContext } from 'react';

const ConfigContext = createContext({
    product: {
        siteUrl: 'https://www.plesk.com/',
        supportUrl: 'https://www.plesk.com/support/',
    },
    webSocket: {
        enabled: true,
    },
    feedback: {
        userVoice: true,
        userVoiceUrl: 'http://plesk.uservoice.com',
    },
    rating: {
        enabled: true,
    },
    promos: {
        pleskFooter: true,
    },
    license: {
        fileUpload: false,
    },
    facebook: {
        showLikeLink: true,
        pleskPage: 'https://www.facebook.com/Plesk',
    },
    twitter: {
        showFollowLink: true,
        pleskPage: 'https://twitter.com/Plesk',
    },
    gdprCookies: {},
});

export default ConfigContext;
export const useConfigContext = () => useContext(ConfigContext);
