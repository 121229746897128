// Copyright 1999-2021. Plesk International GmbH. All rights reserved.

import { StatusMessage } from './status-message';

export class UnresolvedDomainWarning extends StatusMessage {
    _addEvents() {
        super._addEvents();

        this._componentElement.querySelector('a#unresolved-domain-warning')?.addEventListener('click', e => {
            e.preventDefault();
            Plesk.require('app/dns-helper/dns-settings', run => {
                run({
                    id: this._getConfigParam('id'),
                    isAlias: this._getConfigParam('isAlias'),
                    redirectRequest: this._getConfigParam('redirectRequest'),
                });
            });
        });
    }
}
