// Copyright 1999-2021. Plesk International GmbH. All rights reserved.

import { createElement, useEffect, useState } from 'react';
import { PropTypes } from 'prop-types';
import { registerComponent, ObjectSwitcher as JswObjectSwitcher } from 'jsw';
import JswComponent from '../JswComponent';

const ObjectSwitcher = ({ locale, limit, lookUpParams, dataUrl, listUrl, owner, itemUrlTemplate }) => {
    const [component, setComponent] = useState(null);

    useEffect(() => {
        const newComponent = new JswObjectSwitcher({ locale, limit, lookUpParams, dataUrl, listUrl, owner, itemUrlTemplate });
        setComponent(newComponent);

        return () => {
            registerComponent(newComponent.getId());
            setComponent(null);
        };
    }, [locale, limit, lookUpParams, dataUrl, listUrl, owner, itemUrlTemplate]);

    return component ? (
        <JswComponent component="span" key={component.getId()}>
            {component}
        </JswComponent>
    ) : null;
};

ObjectSwitcher.propTypes = {
    locale: PropTypes.object.isRequired,
    limit: PropTypes.number.isRequired,
    lookUpParams: PropTypes.object.isRequired,
    dataUrl: PropTypes.string.isRequired,
    listUrl: PropTypes.string.isRequired,
    owner: PropTypes.string,
    itemUrlTemplate: PropTypes.string.isRequired,
};

ObjectSwitcher.defaultProps = {
    owner: null,
};

export default ObjectSwitcher;
