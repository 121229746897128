// Copyright 1999-2021. Plesk International GmbH. All rights reserved.

import { createElement, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Translate, Form, FormFieldText, Heading } from '@plesk/ui-library';
import { redirect } from 'jsw';
import { withLoginLayout } from './LoginLayout';

const GetPassword = ({ values }) => {
    const handleSubmit = () => {
        document.getElementById('form-changepassword-getlink').submit();
    };

    const handleCancelClick = () => {
        redirect('/login_up.php');
    };

    return (
        <Fragment>
            <Heading level="3"><Translate content="label" /></Heading>
            <p><Translate content="text" /></p>
            <Form
                id="form-changepassword-getlink"
                method="post"
                onSubmit={handleSubmit}
                values={values}
                applyButton={false}
                submitButton={{
                    children: <Translate content="send" />,
                    type: 'submit',
                }}
                cancelButton={{
                    onClick: handleCancelClick,
                    children: <Translate content="cancel" />,
                }}
                vertical
            >
                <FormFieldText
                    name="email"
                    label={<Translate content="emailLabel" />}
                    autoFocus
                    size="fill"
                />

                {document.getElementById('forgery_protection_token') ? (
                    <input
                        type="hidden"
                        name="forgery_protection_token"
                        value={document.getElementById('forgery_protection_token').content}
                    />
                ) : null}
            </Form>
        </Fragment>
    );
};

GetPassword.propTypes = {
    values: PropTypes.shape({
        email: PropTypes.string,
    }).isRequired,
};

export default withLoginLayout(GetPassword);
