// Copyright 1999-2021. Plesk International GmbH. All rights reserved.

export default [
    {
        path: '/admin/setup',
        exact: true,
        component: () => import('./views/admin/setup/index'),
    },
    {
        path: '/admin/setup/progress',
        component: () => import('./views/admin/setup/progress'),
    },
    {
        path: '/admin/home',
        component: () => import('./views/admin/home/admin'),
    },
    {
        path: '/admin/about',
        component: () => import('./views/admin/about/index'),
    },
    {
        path: '/admin/server/tools',
        component: () => import('./views/admin/server/tools'),
    },
    {
        path: '/admin/sessions/list',
        component: () => import('./views/admin/sessions/list'),
    },
    {
        path: ['/admin/help/tutorials', '/smb/help/tutorials'],
        component: () => import('./views/default/help/tutorials'),
    },
    {
        path: '/smb/web/setup',
        component: () => import('./views/smb/web/setup'),
    },
    {
        path: ['/smb/web/view', '/smb/web/overview'],
        component: () => import(/* webpackChunkName: "views/smb/web/view" */'./views/smb/web/view'),
    },
    {
        path: '/smb/statistics/details',
        component: () => import('./views/smb/statistics/details'),
    },
];
