// Copyright 1999-2021. Plesk International GmbH. All rights reserved.

import { createElement, Component, Popover, StatusMessage, Text } from 'react';
import PropTypes from 'prop-types';
import { captureException } from '@sentry/browser';

class ErrorBoundary extends Component {
    state = {
        error: null,
        errorInfo: null,
    };

    componentDidCatch(error, errorInfo) {
        this.setState({
            error,
            errorInfo,
        });
        captureException(error);
    }

    render() {
        if (this.state.errorInfo) {
            return (
                <Popover
                    target={<StatusMessage intent="danger" style={{ margin: 0 }}>{'Internal error'}</StatusMessage>}
                    intent="danger"
                    placement="bottom-start"
                    wide
                >
                    <Text style={{ whiteSpace: 'pre-wrap', fontFamily: 'monospace' }}>
                        {this.state.error && this.state.error.toString()}
                        {this.state.errorInfo.componentStack}
                    </Text>
                </Popover>
            );
        }

        return this.props.children;
    }
}

ErrorBoundary.propTypes = {
    children: PropTypes.any,
};

ErrorBoundary.defaultProps = {
    children: undefined,
};

export default ErrorBoundary;
