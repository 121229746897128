// Copyright 1999-2021. Plesk International GmbH. All rights reserved.

/* eslint-disable react/require-render-return */

import { Component } from './component';
import createComponent from './createComponent';
import render from './render';

export class Container extends Component {
    /**
     * @cfg {Array} items
     */

    _initConfiguration(config) {
        super._initConfiguration(config);

        this._contentAreaId = this._id;

        this._initItems();
    }

    _initItems(items) {
        items = items || this._getConfigParam('items') || [];

        this._items = items.filter(function (item) {
            return !!item;
        }).map(createComponent);
    }

    _renderItems() {
        this._items.forEach(this._renderItem, this);
    }

    _renderItem(item) {
        render(document.getElementById(this._contentAreaId), item);
    }

    getItems() {
        return this._items;
    }

    getItem(id) {
        return this.getItems().find(item => item.getId() === id);
    }

    render() {
        super.render();

        this._renderItems();
    }
}
