// Copyright 1999-2021. Plesk International GmbH. All rights reserved.

import { Component } from './component';
import { list } from './list';
import { Tooltip } from './tooltip';
import isRtl from './isRtl';
import prepareUrl from './prepareUrl';
import render from './render';
import createElement from './createElement';
import escapeHtml from './escapeHtml';
import fireCustomEvent from './fireCustomEvent';
import { ESC, ENTER, LEFT_ARROW, UP_ARROW, RIGHT_ARROW, DOWN_ARROW } from './keyCode';
import api from './api';

export class LookUp extends Component {
    _initConfiguration(config) {
        super._initConfiguration(config);

        this._name = this._getConfigParam('name', null);
        this._cls = this._getConfigParam('cls', 'lookup');
        this._data = this._getConfigParam('data', []);
        this._currentValue = this._getConfigParam('value', null);
        this._newItemValue = this._getConfigParam('newItemValue', null);
        this._dataUrl = this._getConfigParam('dataUrl', null);
        this._copyTitleValue = this._getConfigParam('copyTitleValue', false);
        this._limit = this._getConfigParam('limit', this._dataUrl ? 10 : list.ITEMS_UNLIMITED);
        this._lookUpParams = this._getConfigParam('lookUpParams', {});
        this._disabled = this._getConfigParam('disabled', false);
        this._placeholder = this._getConfigParam('placeholder', null);

        if (!this._currentValue && this._newItemValue) {
            this._currentValue = this._newItemValue;
        }
    }

    _initComponentElement() {
        super._initComponentElement();

        this._valueField = new Element('input', {
            type: 'hidden',
            name: this._name,
        });

        render(this._componentElement, this._valueField);

        const inputFieldConfig = {
            type: 'text',
            class: 'form-control',
        };
        if (this._disabled) {
            inputFieldConfig.disabled = 'disabled';
        }
        if (null !== this._placeholder) {
            inputFieldConfig.placeholder = this._placeholder;
        }
        this._inputField = new Element('input', inputFieldConfig);

        this._lookUpButton = new Element('span', { class: 'form-control-icon form-control-icon-action' });
        render(this._lookUpButton, new Element('i', { class: 'icon-form-control-search' }));

        this._lookUpControl = new Element('div', { class: 'form-control-group' });
        render(this._lookUpControl, this._inputField);
        render(this._lookUpControl, this._lookUpButton);

        this._dropdownList = new Element('ul', { class: 'dropdown-menu lookup-dropdown-menu' });

        this._initCustomLookUpComponent();
        if (this._customLookUpComponent) {
            render(this._dropdownList,
                createElement('li', { class: 'dropdown-header' }, this.lmsg('label'))
            );
            render(this._dropdownList,
                createElement('li',
                    createElement('div', { class: 'dropdown-menu-content' },
                        this._lookUpControl
                    )
                )
            );
        } else {
            this._lookUpControl.classList.add('f-large-size');
            render(this._componentElement, this._lookUpControl);
        }

        this._emptyElement = new Element('li');
        render(this._emptyElement, createElement('div.dropdown-menu-content', this.lmsg('nothingFound')));
        render(this._dropdownList, this._emptyElement);

        render(this._componentElement, this._dropdownList);

        this._updateValue();

        if (0 < this._data.length) {
            this._updateData(this._data);
        }
        if (this._dataUrl && this._limit === list.ITEMS_UNLIMITED) {
            this._getDataByUrl();
        }
    }

    _initCustomLookUpComponent() {
        if (!this._newItemValue) {
            return;
        }

        this._customLookUpComponent = new Element('div', { class: 'input-group' });
        this._customLookUpComponent.innerHTML = (
            '<input type="text" readonly="" class="form-control f-large-size" value="">' +
            '<span class="input-group-btn">' +
                '<button type="button" class="btn dropdown-toggle">' +
                    '<span class="caret"></span>' +
                '</button>' +
            '</span>'
        );
        render(this._componentElement, this._customLookUpComponent);

        render(this._dropdownList, this._createItem(this._newItemValue));
        render(this._dropdownList, new Element('li', { class: 'divider' }));
    }

    _getDataByUrl(filter) {
        this._emptyElement.querySelector('div').innerHTML = this.lmsg('loading');
        this._filter = filter;
        api.get(prepareUrl(this._dataUrl), {
            filter,
            limit: this._limit,
            ...this._lookUpParams,
        })
            .then(response => {
                if (filter !== this._filter) {
                    return;
                }

                this._emptyElement.querySelector('div').innerHTML = this.lmsg('nothingFound');
                if ('error' === response.status) {
                    return;
                }

                this._data = response.data;
                this._updateData(this._data);
                this._selectResults(filter, response.itemsCount - this._data.length);
            });
    }

    isEmpty() {
        return null === this._currentValue;
    }

    getValue() {
        return this._currentValue ? this._currentValue.id : null;
    }

    getItemValue() {
        return this._currentValue ? this._currentValue : null;
    }

    getDisplayValue() {
        return this._currentValue ? this._currentValue.title : '';
    }

    _openList(showAll) {
        this._componentElement.classList.add('open');
        this._lookUpButton.querySelector('i').classList.remove('icon-form-control-search');
        this._lookUpButton.querySelector('i').classList.add('icon-form-control-clear');
        this._fixDropdownPosition();

        const filter = showAll ? '' : this._inputField.value.toLocaleLowerCase();
        if (this._dataUrl && this._limit !== list.ITEMS_UNLIMITED) {
            this._emptyElement.querySelector('div').innerHTML = this.lmsg('loading');
            if (this._getDataTask) {
                clearTimeout(this._getDataTask);
            }
            this._getDataTask = setTimeout(function () {
                this._getDataByUrl(filter);
            }.bind(this), 250);
        } else {
            this._selectResults(filter);
        }
    }

    _closeList() {
        this._updateValue();
        this._componentElement.classList.remove('open');
        this._lookUpButton.querySelector('i').classList.add('icon-form-control-search');
        this._lookUpButton.querySelector('i').classList.remove('icon-form-control-clear');
    }

    _updateValue() {
        this._valueField.value = this._currentValue ? (this._copyTitleValue ? this._currentValue.title : this._currentValue.id) : '';
        this._inputField.value = this._currentValue && !(this._newItemValue && this._currentValue.id === this._newItemValue.id) ? this._currentValue.title : '';
        if (this._newItemValue) {
            this._componentElement.querySelector('.input-group input').value = this._currentValue ? this._currentValue.title : '';
        }
    }

    _selectResults(filter, itemsCount) {
        let itemsFound = 0;
        this._dropdownList.querySelectorAll('li').forEach(item => {
            if (!item._item) {
                return;
            }
            if (this._newItemValue && this._newItemValue.id === item._item.id) {
                if (this._currentValue && this._currentValue.id === this._newItemValue.id) {
                    item.style.display = 'none';
                    item.nextElementSibling.style.display = 'none';
                } else {
                    item.style.display = '';
                    item.nextElementSibling.style.display = '';
                }
                return;
            }
            item.classList.remove('active');
            const itemLink = item.querySelector('a');
            let itemData = itemLink.innerHTML.stripTags();
            const pos = itemData.toLowerCase().indexOf(filter);
            if (-1 !== pos) {
                itemsFound++;
                if (itemsFound <= this._limit) {
                    item.style.display = '';
                    itemData = `${
                        itemData.substr(0, pos)
                    }<b class="search-result-label">${
                        itemData.substr(pos, filter.length)
                    }</b>${
                        itemData.substr(pos + filter.length)
                    }`;
                } else {
                    item.style.display = 'none';
                }
            } else {
                item.style.display = 'none';
            }
            itemLink.innerHTML = itemData;
        });

        this._emptyElement.style.display = itemsFound ? 'none' : '';
        if (itemsFound) {
            const visibleItems = [...this._dropdownList.querySelectorAll('li')].filter(el => el.style.display !== 'none');
            if (visibleItems.length) {
                visibleItems[0].classList.add('active');
            }
        }

        itemsFound += itemsCount || 0;

        if (this._searchMoreElement) {
            if (itemsFound > this._limit) {
                this._searchMoreElement.querySelector('div').innerHTML = this._getSearchMoreText(itemsFound - this._limit);
                this._searchMoreElement.style.display = '';
                this._searchMoreElement.previousElementSibling.style.display = '';
            } else {
                this._searchMoreElement.style.display = 'none';
                this._searchMoreElement.previousElementSibling.style.display = 'none';
            }
        }

        this._fixDropdownPosition();
    }

    _addEvents() {
        if (this._disabled) {
            return;
        }

        super._addEvents();

        if (this._customLookUpComponent) {
            this._customLookUpComponent.addEventListener('click', event => {
                event.preventDefault();
                Tooltip.hide();
                if (this._componentElement.classList.contains('open')) {
                    this._inputField.blur();
                } else {
                    this._openList(true);
                    this._inputField.focus();
                }
            });
        } else {
            this._inputField.addEventListener('focus', this._inputOnFocus.bind(this));
        }
        this._inputField.addEventListener('paste', this._inputOnPaste.bind(this));
        this._inputField.addEventListener('blur', this._inputOnBlur.bind(this));
        this._inputField.addEventListener('keyup', this._inputOnKeyUp.bind(this));
        this._inputField.addEventListener('keydown', this._inputOnKeyDown.bind(this));
        this._lookUpButton.addEventListener('click', this._lookUpButtonOnClick.bind(this));
    }

    _inputOnFocus() {
        this._openList(true);
    }

    _inputOnBlur() {
        // workaround for Google Chrome
        setTimeout(() => {
            this._closeList();
        }, 300);
    }

    _inputOnPaste() {
        setTimeout(() => {
            this._openList();
            const activeItem = this._dropdownList.querySelector('li.active');
            if (activeItem) {
                this._currentValue = activeItem._item;
                this._updateValue();
            }
        }, 300);
    }

    _inputOnKeyUp(event) {
        if ([UP_ARROW, DOWN_ARROW, LEFT_ARROW, RIGHT_ARROW, ENTER, ESC].indexOf(event.keyCode) !== -1) {
            return;
        }

        this._openList();
    }

    _inputOnKeyDown(event) {
        if ([UP_ARROW, DOWN_ARROW].indexOf(event.keyCode) !== -1) {
            this._onArrowKeyPressed(event.keyCode);
        }

        if (ESC === event.keyCode) {
            this._closeList();
        }

        if (ENTER === event.keyCode) {
            const activeItem = this._dropdownList.querySelector('li.active');
            if (activeItem) {
                this._currentValue = activeItem._item;
                this._closeList();
                fireCustomEvent(this._componentElement, 'component:change');
            } else {
                this._closeList();
                this._openList();
            }
        }
    }

    _onArrowKeyPressed(keyCode) {
        let nextItem = null;
        const activeItem = this._dropdownList.querySelector('li.active');
        if (!activeItem) {
            nextItem = this._dropdownList.querySelector('li');
            while (nextItem && !(nextItem.style.display !== 'none' && nextItem._item)) {
                nextItem = nextItem.nextElementSibling;
            }
        } else if (DOWN_ARROW === keyCode) {
            nextItem = activeItem.nextElementSibling;
            while (nextItem && !(nextItem.style.display !== 'none' && nextItem._item)) {
                nextItem = nextItem.nextElementSibling;
            }
        } else if (UP_ARROW === keyCode) {
            nextItem = activeItem.previousElementSibling;
            while (nextItem && !(nextItem.style.display !== 'none' && nextItem._item)) {
                nextItem = nextItem.previousElementSibling;
            }
        }
        if (nextItem && nextItem.style.display !== 'none' && nextItem._item) {
            nextItem.classList.add('active');
            if (activeItem) {
                activeItem.classList.remove('active');
            }
            this._scrollDropdownList();
        }
    }

    _scrollDropdownList() {
        const firstItem = this._dropdownList.querySelector('li.dropdown-menu-list-item');
        const activeItem = this._dropdownList.querySelector('li.active');

        if (!firstItem || !activeItem) {
            return;
        }

        const position = Math.round(activeItem.offsetTop / firstItem.offsetHeight) + 1;
        const itemsInFrame = Math.round(this._dropdownList.clientHeight / firstItem.offsetHeight);

        this.frameStart = this.frameStart || 1;

        if (position >= this.frameStart + itemsInFrame) {
            this._dropdownList.scrollTop += firstItem.offsetHeight;
            this.frameStart++;
        } else if (position <= this.frameStart) {
            this._dropdownList.scrollTop -= firstItem.offsetHeight;
            this.frameStart--;
        }
    }

    _lookUpButtonOnClick(event) {
        event.preventDefault();

        if (this._componentElement.classList.contains('open')) {
            this._closeList();
        } else {
            this._openList(true);
        }
    }

    _itemOnClick(event) {
        event.preventDefault();
        this._currentValue = event.target.closest('li')._item;
        this._closeList();
        fireCustomEvent(this._componentElement, 'component:change');
    }

    _itemOnOver() {
        this._dropdownList.querySelectorAll('li.active').forEach(element => {
            element.classList.remove('active');
        });
    }

    _createItem(item) {
        const listItem = new Element('li', { class: 'dropdown-menu-list-item' });
        listItem.innerHTML = `<a href="#">${escapeHtml(item.title)}</a>`;
        listItem._item = item;
        listItem.addEventListener('click', this._itemOnClick.bind(this));
        listItem.addEventListener('mouseover', this._itemOnOver.bind(this));

        return listItem;
    }

    _getSearchMoreText(count) {
        return this.lmsg('moreObjectsAvailable', { count });
    }

    _updateData(data) {
        this._clearData();

        data.each(item => {
            render(this._dropdownList, this._createItem(item));
        });

        this._addSearchMoreElement();
    }

    _clearData() {
        let el;
        while ((el = this._emptyElement.nextSibling)) {
            el.parentNode.removeChild(el);
        }
    }

    _addSearchMoreElement() {
        this._searchMoreElement = document.createElement('li');
        this._searchMoreElement.innerHTML = '<div class="dropdown-menu-content"></div>';
        this._searchMoreElement.style.display = 'none';

        render(this._dropdownList, '<li class="divider" style="display: none;"></li>');
        render(this._dropdownList, this._searchMoreElement);
    }

    _fixDropdownPosition() {
        this._dropdownList.style[isRtl() ? 'right' : 'left'] = null;

        const windowWidth = document.documentElement.clientWidth;
        const offsets = this._dropdownList.getBoundingClientRect();

        const delta = (isRtl() ? offsets.left : (windowWidth - offsets.width - offsets.left))
            - parseInt(window.getComputedStyle(document.querySelector('.pul-layout__main-inner')).paddingLeft || 0);

        if (delta < 0) {
            this._dropdownList.style[isRtl() ? 'right' : 'left'] = `${delta}px`;
        }
    }
}

// TODO PMT-4391: Plesk migrator is broken in Plesk 17.9.1 after cutting core's classes
LookUp.subclasses = [];
