// Copyright 1999-2021. Plesk International GmbH. All rights reserved.

import { createElement } from 'react';
import { Switch, Route } from 'react-router-dom';
import RouteRequire from './RouteRequire';
import PageContent from './PageContent';
import { useDataContext } from './DataContext';
import routes from '../routes';

const PhpPage = () => {
    const { data: { withSecondary } } = useDataContext();

    return (
        <PageContent withSecondary={withSecondary} />
    );
};

const Routes = () => (
    <Switch>
        {routes.map(({ path, ...props }) => (
            <RouteRequire key={path} path={path} {...props} />
        ))}
        <Route path="*">
            <PhpPage />
        </Route>
    </Switch>
);

export default Routes;
