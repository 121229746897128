// Copyright 1999-2021. Plesk International GmbH. All rights reserved.

import { useEffect } from 'react';
import useModal from 'plesk/hooks/useModal';

const emitter = {
    listeners: [],

    on(listener) {
        this.listeners.push(listener);
    },

    off(listener) {
        this.listeners = this.listeners.filter(l => l !== listener);
    },

    emit(flag) {
        this.listeners.forEach(listener => {
            listener(flag ? flag : null);
        });
    },
};

export const togglePreferences = flag => {
    emitter.emit(flag);
};

const usePreferencesToggle = () => {
    const [isVisible, setVisible] = useModal('cookie-policy-preferences');

    useEffect(() => {
        emitter.on(setVisible);
        return () => {
            emitter.off(setVisible);
        };
    }, [setVisible]);

    const emitVisible = flag => {
        emitter.emit(flag);
    };

    return [isVisible !== null, emitVisible];
};

export default usePreferencesToggle;
