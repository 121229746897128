// Copyright 1999-2021. Plesk International GmbH. All rights reserved.

import { toollist } from './tool-list';

const { Button } = toollist;

export class CustomButton extends Button {
    /**
     * @param {String} path
     * @returns {String}
     */
    getImagePath(path) {
        return path;
    }
}

if (window.Form) {
    Form.Element.Methods.disable = element => {
        element.disabled = 'disabled';
        element.classList.add('disabled');
        return element;
    };

    Form.Element.Methods.enable = element => {
        element.disabled = false;
        element.classList.remove('disabled');
        return element;
    };

    Element.addMethods();
}
