// Copyright 1999-2021. Plesk International GmbH. All rights reserved.

import { useEffect } from 'react';

const useClickOutside = (refs, handler) => {
    useEffect(
        () => {
            const listener = event => {
                if (refs.some(({ current }) => !current || current.contains(event.target))) {
                    return;
                }

                handler(event);
            };

            document.addEventListener('mousedown', listener);
            document.addEventListener('touchstart', listener);

            return () => {
                document.removeEventListener('mousedown', listener);
                document.removeEventListener('touchstart', listener);
            };
        },
        [refs, handler]
    );
};

export default useClickOutside;
