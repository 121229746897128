// Copyright 1999-2021. Plesk International GmbH. All rights reserved.

import { createElement } from 'react';
import PropTypes from 'prop-types';
import { Drawer } from '@plesk/ui-library';

const DrawerAdapter = ({
    isOpen,
    onSubmit,
    onCancel,
    submitButton,
    cancelButton,
    title,
    children,
    values,
}) => (
    <Drawer
        isOpen={isOpen}
        onClose={onCancel}
        data-type="cookie-policy-preferences"
        title={title}
        size="sm"
        form={{
            vertical: true,
            onSubmit,
            onCancel,
            applyButton: false,
            submitButton,
            cancelButton,
            values,
        }}
    >
        {children}
    </Drawer>
);

DrawerAdapter.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    onSubmit: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    submitButton: PropTypes.shape({
        children: PropTypes.node.isRequired,
    }).isRequired,
    cancelButton: PropTypes.shape({
        children: PropTypes.node.isRequired,
        onClick: PropTypes.func.isRequired,
    }).isRequired,
    title: PropTypes.node.isRequired,
    children: PropTypes.node.isRequired,
    values: PropTypes.object.isRequired,
};

export default DrawerAdapter;
