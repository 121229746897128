// Copyright 1999-2021. Plesk International GmbH. All rights reserved.

/* eslint-disable react/jsx-max-depth */

import { createElement, useState } from 'react';
import PropTypes from 'prop-types';
import JswComponent from 'plesk/components/JswComponent';
import { Layout, LocaleProvider } from '@plesk/ui-library';
import Cookie from 'common/cookie';
import LoginMobileApplicationPromotion from './LoginMobileApplicationPromotion';
import SuggestResolvableHostname from './SuggestResolvableHostname';
import Brand from './Brand';
import Status from './Status';
import CookiePolicy from './CookiePolicy';
import { useDataContext } from './DataContext';

const MOBILE_APPLICATION_PROMOTION_COOKIE_NAME = 'mobile-application-promotion-hidden';

const LoginLayout = ({ mobileApplicationPromotion, extensionsAdditionalBody, status, children, sslWarning }) => {
    const { data: { logo } } = useDataContext();

    const [
        isMobileApplicationPromotionVisible,
        setMobileApplicationPromotionVisible,
    ] = useState(Boolean(mobileApplicationPromotion && Cookie.get(MOBILE_APPLICATION_PROMOTION_COOKIE_NAME) !== '1'));

    const handleMobileApplicationPromotionHide = () => {
        setMobileApplicationPromotionVisible(false);
        Cookie.setPermanent(MOBILE_APPLICATION_PROMOTION_COOKIE_NAME, '1', '/');
    };

    return (
        <div className="login-page">
            <CookiePolicy layoutType="simplified" />
            <Layout
                type="simplified"
                className="login-page__inner"
                header={(
                    <div className="login-page-header">
                        {logo ? (
                            <div className="login-page-header__brand">
                                <Brand {...logo} />
                            </div>
                        ) : null}
                    </div>
                )}
                contentAddon={
                    [
                        sslWarning ? <SuggestResolvableHostname {...sslWarning} /> : null,
                        isMobileApplicationPromotionVisible ? (
                            <LoginMobileApplicationPromotion {...mobileApplicationPromotion} onHide={handleMobileApplicationPromotionHide} />
                        ) : null,
                    ]
                }
            >
                <div id="main" className="login-page__content">
                    <Status messages={status} />
                    {children}
                    {extensionsAdditionalBody ? (
                        <JswComponent>{extensionsAdditionalBody}</JswComponent>
                    ) : null}
                </div>
            </Layout>
        </div>
    );
};

LoginLayout.propTypes = {
    mobileApplicationPromotion: PropTypes.object,
    extensionsAdditionalBody: PropTypes.string,
    status: PropTypes.array,
    children: PropTypes.any,
    sslWarning: PropTypes.object,
};

LoginLayout.defaultProps = {
    mobileApplicationPromotion: undefined,
    extensionsAdditionalBody: undefined,
    status: [],
    children: undefined,
    sslWarning: undefined,
};

export const withLoginLayout = Component => {
    const Wrapper = ({
        /* eslint-disable react/prop-types */
        mobileApplicationPromotion,
        extensionsAdditionalBody,
        status,
        sslWarning,
        locale,
        /* eslint-enable react/prop-types */
        ...props
    }) => (
        <LocaleProvider messages={locale}>
            <LoginLayout
                mobileApplicationPromotion={mobileApplicationPromotion}
                extensionsAdditionalBody={extensionsAdditionalBody}
                status={status}
                sslWarning={sslWarning}
            >
                <Component {...props} />
            </LoginLayout>
        </LocaleProvider>
    );
    Wrapper.displayName = `withLoginLayout(${Component.displayName || Component.name || 'Component'})`;
    Wrapper.WrappedComponent = Component;

    return Wrapper;
};

export default LoginLayout;
