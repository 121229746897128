// Copyright 1999-2021. Plesk International GmbH. All rights reserved.

import { useHistory } from 'react-router-dom';

const useModal = path => {
    const key = `modals[${path}]`;
    const history = useHistory();
    const value = new URLSearchParams(window.location.search).get(key);

    const setValue = next => {
        const { pathname, search } = window.location;
        const params = new URLSearchParams(search);
        if (next) {
            params.set(key, next);
            history.push(`${pathname}?${params}`);
        } else if (params.has(key)) {
            params.delete(key);
            history.push(`${pathname}?${params}`);
        }
    };

    return [value, setValue];
};

export default useModal;
