// Copyright 1999-2021. Plesk International GmbH. All rights reserved.

import { Component } from './component';

/**
 * Example usage:
 *
 *     @example
 *     new Gauge({
 *         renderTo: document.body,
 *         value: 75
 *     });
 */
export class Gauge extends Component {
    /**
     * @cfg {Number} value
     */

    _initConfiguration(config) {
        super._initConfiguration({
            cls: 'progress progress-sm',
            ...config,
        });
    }

    _initComponentElement() {
        super._initComponentElement();

        this.setProgress(this._getConfigParam('value', ''));
    }

    setProgress(value) {
        this._componentElement.innerHTML = `<div class="progress-bar" style="width: ${value}%"></div>`;
    }
}
